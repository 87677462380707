import { UserID } from '../../../users/usersTypes';

import { FetchItemsCacheKey } from '../../../../types';
import { TaskNanoID, TaskUUID } from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface InviteUsersToTaskOptions {
  query: string;
  cacheKeys: FetchItemsCacheKey[];
}

export interface InviteUsersToTaskResponse<InviteUsersToTaskRecordType> {
  inviteUsersToTask: {
    status: string;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: InviteUsersToTaskRecordType;
    errors: InviteUsersToTaskError;
  };
}

export interface InviteUsersToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  userIds: UserID[];
}

export interface InviteUsersToTaskError {
  fullMessages: string[] | null;
}

const action = 'inviteUsersToTask';

const scope = 'task';

const pluralScope = 'tasks';

function useInviteUsersToTask<InviteUsersToTaskRecordType>({
  query,
  cacheKeys
}: InviteUsersToTaskOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    InviteUsersToTaskInput,
    InviteUsersToTaskResponse<InviteUsersToTaskRecordType>,
    InviteUsersToTaskError,
    InviteUsersToTaskRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    inviteUsersToTask: updateQuery,
    inviteUsersToTaskData: updateQueryData,
    inviteUsersToTaskError: updateQueryError,
    inviteUsersToTaskErrorMessage: updateQueryErrorMessage,
    inviteUsersToTaskLoading: updateQueryLoading,
    inviteUsersToTaskReset: updateQueryReset
  };
}

export default useInviteUsersToTask;
