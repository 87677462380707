import { gql } from 'graphql-request';

export const CHECK_IN_TO_SMART_CONTRACT_SHARE_QUERY = gql`
  mutation CheckInToSmartContractShare($uuid: ID!, $withoutEventIds: [ID!]) {
    checkInToSmartContractShare(
      input: { uuid: $uuid, withoutEventIds: $withoutEventIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
