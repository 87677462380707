import React from 'react';

import { SmartContractID } from '../../../../smartContracts/smartContractsTypes';
import { ClassName, IsDisabled } from '../../../../../types';
import {
  SmartContractShareID,
  UpdateSmartContractSharesCacheKeys
} from '../../../../smartContractShares/smartContractSharesTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';
import { TooltipDropdownHelper } from '../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { TaskCheckInToSmartContractShareButton } from '../TaskCheckInToSmartContractShareButton';

import { IconsEnum } from '../../../../../assets/icons/types';
import { tasksKeys } from '../../../../../locales/keys';
import { TaskNanoID } from '../../../tasksTypes';

interface TaskCheckInWithOptionsButtonProps {
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  firstButtonAction: string;
  secondButtonAction: string;
  taskNanoId: TaskNanoID;
  smartContractId?: SmartContractID;
  checkInSmartContractShareId?: SmartContractShareID;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  disabled?: IsDisabled;
  afterCheckIn?: () => void;
}

function TaskCheckInWithOptionsButton({
  className,
  icon,
  iconClassName,
  firstButtonAction,
  secondButtonAction,
  taskNanoId,
  smartContractId,
  checkInSmartContractShareId,
  cacheKeys,
  disabled,
  afterCheckIn
}: TaskCheckInWithOptionsButtonProps) {
  const currentUser = useCurrentUser();

  const withFirstButton = currentUser.hasPermissions(firstButtonAction);
  const withSecondButton = currentUser.hasPermissions(secondButtonAction);

  if (!withFirstButton && !withSecondButton) {
    return null;
  }

  if (!withFirstButton) {
    return (
      <TaskCheckInToSmartContractShareButton
        tooltipI18nText={tasksKeys.buttons.checkInWithoutFollowers}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        smartContractId={smartContractId}
        taskNanoId={taskNanoId}
        checkInSmartContractShareId={checkInSmartContractShareId}
        cacheKeys={cacheKeys}
        disabled={disabled}
        afterCheckIn={afterCheckIn}
        withoutFollowers
      />
    );
  }

  if (!withSecondButton) {
    return (
      <TaskCheckInToSmartContractShareButton
        tooltipI18nText={tasksKeys.buttons.checkInWithFollowers}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        smartContractId={smartContractId}
        taskNanoId={taskNanoId}
        checkInSmartContractShareId={checkInSmartContractShareId}
        cacheKeys={cacheKeys}
        disabled={disabled}
        afterCheckIn={afterCheckIn}
      />
    );
  }

  return (
    <TooltipDropdownHelper
      tooltipI18nText={tasksKeys.buttons.checkIn}
      buttonClassName={className}
      icon={icon}
      iconClassName={iconClassName}
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
    >
      <TaskCheckInToSmartContractShareButton
        i18nText={tasksKeys.buttons.checkInWithFollowers}
        smartContractId={smartContractId}
        taskNanoId={taskNanoId}
        checkInSmartContractShareId={checkInSmartContractShareId}
        cacheKeys={cacheKeys}
        disabled={disabled}
        afterCheckIn={afterCheckIn}
        className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      />
      <TaskCheckInToSmartContractShareButton
        i18nText={tasksKeys.buttons.checkInWithoutFollowers}
        smartContractId={smartContractId}
        taskNanoId={taskNanoId}
        checkInSmartContractShareId={checkInSmartContractShareId}
        cacheKeys={cacheKeys}
        disabled={disabled}
        afterCheckIn={afterCheckIn}
        className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
        withoutFollowers
      />
    </TooltipDropdownHelper>
  );
}

export default TaskCheckInWithOptionsButton;
