import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface InviteUsersToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const INVITE_USERS_TO_TASK_QUERY = gql`
  mutation InviteUsersToTask($uuid: ID!, $userIds: [ID!]!) {
    inviteUsersToTask(input: { uuid: $uuid, userIds: $userIds }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        id
        uuid
        nanoId
        name
      }
    }
  }
`;
