import React, { useCallback } from 'react';

import {
  ClassName,
  GADataType,
  I18nText,
  IsDisabled
} from '../../../../../types';
import { SmartContractShareID } from '../../../smartContractSharesTypes';
import { CompanyEventTemplateID } from '../../../../companyEventTemplates/companyEventTemplatesTypes';
import { UpdateItemsCacheKeys } from '../../../../../types/updateItemsTypes';

import { CHECK_IN_TO_SMART_CONTRACT_SHARE_QUERY } from '../../../queries/checkInToSmartContractShare.query';

import { useCheckInSmartContractShare } from '../../../hooks/useCheckInSmartContractShare';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { IconsEnum } from '../../../../../assets/icons/types';

interface CheckInSmartContractShareButtonProps {
  smartContractShareId: SmartContractShareID;
  icon?: IconsEnum;
  className?: ClassName;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipI18nText?: I18nText;
  disabled?: IsDisabled;
  cacheKeys: UpdateItemsCacheKeys;
  afterCheckIn?: () => void;
  withoutEventIds?: CompanyEventTemplateID[];
}

function CheckInSmartContractShareButton({
  smartContractShareId,
  icon,
  className,
  iconClassName,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  disabled,
  cacheKeys,
  afterCheckIn,
  withoutEventIds,
  dataGa
}: CheckInSmartContractShareButtonProps & GADataType) {
  const {
    checkInSmartContractShare,
    checkInSmartContractShareLoading,
    checkInSmartContractShareErrorMessage
  } = useCheckInSmartContractShare({
    query: CHECK_IN_TO_SMART_CONTRACT_SHARE_QUERY,
    cacheKeys
  });

  const handleCheckInSmartContractShare = useCallback<() => void>(() => {
    checkInSmartContractShare({
      uuid: smartContractShareId,
      withoutEventIds
    }).then(() => {
      afterCheckIn?.();
    });
  }, [
    afterCheckIn,
    checkInSmartContractShare,
    smartContractShareId,
    withoutEventIds
  ]);

  useShowToastOnErrorChange({
    error: checkInSmartContractShareErrorMessage
  });

  return (
    <PureTooltipIconButtonHelper
      onClick={handleCheckInSmartContractShare}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      className={className}
      disabled={disabled || checkInSmartContractShareLoading}
      dataGa={dataGa}
    />
  );
}

export default CheckInSmartContractShareButton;
