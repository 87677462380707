import {
  CreateSmartContractShareGqlQuery,
  CreateSmartContractShareGqlStatus,
  SmartContractShareID,
  SmartContractShareUUID,
  SmartContractShareIteration,
  SmartContractShareShare,
  SmartContractShareShareType,
  SmartContractShareGqlError,
  SmartContractShareStatus,
  UpdateSmartContractSharesCacheKeys,
  SmartContractShareFinalAt
} from '../../smartContractSharesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';
import { SmartContractID } from '../../../smartContracts/smartContractsTypes';
import { GeneralLedgerID } from '../../../generalLedgers/generalLedgersTypes';
import { CompanyEventTemplateID } from '../../../companyEventTemplates/companyEventTemplatesTypes';

interface CreateSmartContractShareOptions {
  query: CreateSmartContractShareGqlQuery;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
}

interface CreateSmartContractShareRecordResponse {
  id: SmartContractShareID;
}

export interface CreateSmartContractShareResponse {
  createSmartContractShare: {
    status: CreateSmartContractShareGqlStatus;
    recordUuid: SmartContractShareUUID;
    record: CreateSmartContractShareRecordResponse;
    errors: CreateSmartContractShareError;
  };
}

export interface CreateSmartContractShareInput {
  iteration: SmartContractShareIteration;
  share: SmartContractShareShare;
  shareType: SmartContractShareShareType;
  smartContractId: SmartContractID;
  generalLedgerId: GeneralLedgerID;
  status: SmartContractShareStatus;
  finalAt?: SmartContractShareFinalAt;
  withoutEventIds?: CompanyEventTemplateID[];
}

export interface CreateSmartContractShareError {
  fullMessages: SmartContractShareGqlError;
}

const action = 'createSmartContractShare';

function useCreateSmartContractShare({
  query,
  cacheKeys
}: CreateSmartContractShareOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateSmartContractShareInput,
    CreateSmartContractShareResponse,
    CreateSmartContractShareError,
    CreateSmartContractShareRecordResponse
  >({ action, cacheKeys, query });

  return {
    createSmartContractShareData: createQueryData,
    createSmartContractShareError: createQueryError,
    createSmartContractShareLoading: createQueryLoading,
    createSmartContractShareErrorMessage: createQueryErrorMessage,
    createSmartContractShare: createQuery,
    createSmartContractShareReset: createQueryReset
  };
}

export default useCreateSmartContractShare;
