import React, { useMemo } from 'react';
import map from 'lodash/map';

import { SmartContractID } from '../../../../smartContracts/smartContractsTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { TaskNanoID } from '../../../tasksTypes';
import {
  SmartContractShareID,
  SmartContractShareStatuses,
  UpdateSmartContractSharesCacheKeys
} from '../../../../smartContractShares/smartContractSharesTypes';

import {
  FETCH_FIN_SELECT_COMPANY_EVENT_TEMPLATES_QUERY,
  FetchFinSelectCompanyEventTemplateQueryResponse
} from '../../../../companyEventTemplates/queries/fetchFinSelectCompanyEventTemplates.query';

import { useFinPaginatedCompanyEventTemplates } from '../../../../companyEventTemplates/hooks/useFinPaginatedCompanyEventTemplates';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { CheckInSmartContractShareButton } from '../../../../smartContractShares/components/buttons/CheckInSmartContractShareButton';
import { CreateSmartContractShareModalButton } from '../../../../smartContractShares/components/modalButtons/CreateSmartContractShareModalButton';

import { CompanyEventTemplateCache } from '../../../../companyEventTemplates/CompanyEventTemplateCache';

import { IconsEnum } from '../../../../../assets/icons/types';
import { tasksKeys } from '../../../../../locales/keys';

interface TaskCheckInToSmartContractShareButtonProps {
  taskNanoId: TaskNanoID;
  smartContractId?: SmartContractID;
  checkInSmartContractShareId?: SmartContractShareID;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  disabled?: IsDisabled;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipI18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  afterCheckIn?: () => void;
  withoutFollowers?: boolean;
}

function TaskCheckInToSmartContractShareButton({
  taskNanoId,
  smartContractId,
  checkInSmartContractShareId,
  cacheKeys,
  disabled,
  className,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  icon,
  iconClassName,
  afterCheckIn,
  withoutFollowers
}: TaskCheckInToSmartContractShareButtonProps) {
  const currentUser = useCurrentUser();

  const {
    companyEventTemplates,
    companyEventTemplatesErrorMessage,
    companyEventTemplatesFetched
  } = useFinPaginatedCompanyEventTemplates<FetchFinSelectCompanyEventTemplateQueryResponse>(
    {
      cacheKey: withoutFollowers
        ? CompanyEventTemplateCache.selectCompanyCacheKey(
            currentUser.currentTeam.nanoId
          )
        : '',
      query: FETCH_FIN_SELECT_COMPANY_EVENT_TEMPLATES_QUERY,
      initialFilters: {
        companyNanoId: {
          eq: currentUser.currentTeam.nanoId
        },
        disabledAt: {
          isNull: true
        }
      },
      options: {
        withoutPrefetch: true,
        enabled: !!withoutFollowers,
        enabledPlaceholder: false
      }
    }
  );

  useShowToastOnErrorChange({
    error: companyEventTemplatesErrorMessage
  });

  const disabledCheckIn = withoutFollowers
    ? disabled || !companyEventTemplatesFetched
    : disabled;

  const withoutEventIds = useMemo(
    () => map(companyEventTemplates, 'id'),
    [companyEventTemplates]
  );

  return (
    <>
      {checkInSmartContractShareId && (
        <CheckInSmartContractShareButton
          className={className}
          disabled={disabledCheckIn}
          smartContractShareId={checkInSmartContractShareId}
          cacheKeys={cacheKeys}
          i18nText={i18nText}
          i18nTextClassName={i18nTextClassName}
          tooltipI18nText={tooltipI18nText}
          icon={icon}
          iconClassName={iconClassName}
          afterCheckIn={afterCheckIn}
          withoutEventIds={withoutEventIds}
        />
      )}
      {!checkInSmartContractShareId && smartContractId && (
        <CreateSmartContractShareModalButton
          taskNanoId={taskNanoId}
          smartContractId={smartContractId}
          status={SmartContractShareStatuses.CHECKED_IN}
          i18nTitle={tasksKeys.buttons.checkIn}
          cacheKeys={cacheKeys}
          className={className}
          i18nText={i18nText}
          i18nTextClassName={i18nTextClassName}
          tooltipI18nText={tooltipI18nText}
          icon={icon}
          iconClassName={iconClassName}
          disabled={disabledCheckIn}
          defaultUsers={[currentUser]}
          afterCreate={afterCheckIn}
          withoutEventIds={withoutEventIds}
        />
      )}
    </>
  );
}

export default TaskCheckInToSmartContractShareButton;
