import { gql } from 'graphql-request';
import {
  CompanyEventTemplateUUID,
  CompanyEventTemplateID,
  CompanyEventTemplateNanoID
} from '../companyEventTemplatesTypes';

export interface FetchFinSelectCompanyEventTemplateQueryResponse {
  id: CompanyEventTemplateID;
  uuid: CompanyEventTemplateUUID;
  nanoId: CompanyEventTemplateNanoID;
}

export const FETCH_FIN_SELECT_COMPANY_EVENT_TEMPLATES_QUERY = gql`
  query FinSelectCompanyEventTemplates(
    $filters: CompanyEventTemplatesFilters
    $sort: [CompanyEventTemplatesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    companyEventTemplates(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
